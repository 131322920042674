@media print {
    /* hide elements */


    /* page breaks */
    h1
    h2,
    h3,
    h4{
        page-break-after: avoid;
    }

    tr,
    img,
    .teaser,
    footer {
        page-break-inside: avoid;
    }

    /* reset bootstrap default stuff */
    a[href]:after,
    abbr[title]:after,
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }


    /* reset mobile grid */
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-11 {
        width: 91.66666667%;
    }
    .col-md-10 {
        width: 83.33333333%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-8 {
        width: 66.66666667%;
    }
    .col-md-7 {
        width: 58.33333333%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-5 {
        width: 41.66666667%;
    }
    .col-md-4 {
        width: 33.33333333%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-2 {
        width: 16.66666667%;
    }
    .col-md-1 {
        width: 8.33333333%;
    }

    .navbar-wrapper {
        display:none;
    }
    /* slick slider */
    .slick-prev,
    .slick-next, .slick-dots{
        display: none !important;
    }
    /* cookie bar */
    .cookie-bar {
        display: none !important;
    }
    /* breadcrumbs */
    .breadcrumb {
        display: none;
    }
    .teaser-slider__arrow {
        display:none !important;
    }
    .hero__title {
        opacity: 1 !important;
        transform: translateY(0px);
        visibility: visible;
    }
    .hero__bg-img {
        transform: scale(1);
    }
    .img-teaser:before {
        display:none !important;
    }
    .hero__body {
        background:transparent !important;
    }
    .timeline-row {
        page-break-inside: avoid !important;
    }
    .timeline-teaser__badge {
        position:relative !important;
        bottom: initial !important;
        top: initial !important;
        transform: none !important;
        left: 0 !important;
        color:#E88300 !important;
        justify-content: flex-start !important;
        right: initial !important;
        height:auto !important;
        padding: 20px 0 !important;
    }
    .timeline-row+.timeline-row {
        margin-top: 10px !important;
    }

    .timeline-teaser__body {
        box-shadow:none !important;
    }
}
